<template>
  <base-material-card
    color="primary"
    icon="mdi-file-plus-outline"
    inline
    class="px-5 py-4 ml-0 pb-10"
  >
    <template v-slot:after-heading>
      <div class="display-2 font-weight-light">Adicionar Relatório Modelo</div>
    </template>
    <v-divider class="my-4" />
    <v-row>
      <v-col cols="6">
        <validation-provider
          v-slot="{ errors }"
          rules="required"
        >
          <v-text-field
            v-model="nome"
            id="nome-rel-m"
            label="Nome do Relatório Modelo"
            outlined
            :error-messages="errors"
          />
        </validation-provider>
      </v-col>
      <v-col cols="6">
        <validation-provider
          v-slot="{ errors }"
          rules="required"
        >
          <v-select
            v-model="grupoId"
            id="grupo-rel-m"
            class="style-slot-btn-grupos-rm grupo-rm-min-width"
            :items="grupos"
            item-value="id"
            item-text="titulo"
            label="Grupo do Relatório Modelo"
            outlined
            :error-messages="errors"
          >
            <template slot="append-outer">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    id="btn-crud-grupos"
                    color="primary"
                    fab
                    height="40"
                    width="40"
                    v-on="on"
                    v-bind="attrs"
                    @click="$router.replace({ name: 'Grupos de Relatórios' })"
                  >
                    <v-icon medium>
                      mdi-file-table-box-multiple-outline
                    </v-icon>
                  </v-btn>
                </template>
                <span>
                  Clique aqui para acessar o menu de cadastro / edição de grupos
                  de relatórios
                </span>
              </v-tooltip>
            </template>
          </v-select>
        </validation-provider>
      </v-col>
    </v-row>
    <v-row class="mt-n5 mr-10 mb-n10">
      <v-col cols="6 py-0" />
      <v-col
        cols="6"
        class="py-0 pr-7"
        style="padding-left: 38px !important"
      >
        <validation-provider
          v-slot="{ errors }"
          rules="required"
          id="exclusivo-rel-m"
        >
          <v-select
            v-model="exclusivo"
            :items="['Sim', 'Não']"
            label="Exclusivo"
            :error-messages="errors"
            outlined
          />
        </validation-provider>
      </v-col>
    </v-row>
    <v-row class="mt-n9">
      <v-col cols="12">
        <v-card id="primeiro-grupo-tabs-rm">
          <v-toolbar flat>
            <v-tabs
              v-model="tab"
              grow
            >
              <v-tab
                v-for="tab in itemsTabs"
                :key="tab"
              >
                {{ tab }}
              </v-tab>
            </v-tabs>
          </v-toolbar>
          <v-tabs-items v-model="tab">
            <v-tab-item
              v-for="tab in itemsTabs"
              :key="tab"
            >
              <template v-if="tab == 'Consulta SQL'">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                >
                  <v-textarea
                    class="mx-4 ta-padding-c"
                    v-model="consultaSQL"
                    rows="16"
                    hint="Não insira ';' (ponto e vírgula) no final da query"
                    no-resize
                    clearable
                    background-color="#EEEEEE"
                    placeholder="Escreva aqui sua consulta SQL"
                    :error-messages="errors"
                  />
                </validation-provider>
              </template>
              <template v-else>
                <v-textarea
                  class="mx-4 ta-padding-c"
                  v-model="descricao"
                  rows="16"
                  no-resize
                  clearable
                  background-color="#EEEEEE"
                  placeholder="Escreva aqui uma descrição sucinta do que é esse relatório modelo"
                />
              </template>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <slot name="footer" />
      </v-col>
    </v-row>
  </base-material-card>
</template>

<script>
import GruposRelatoriosService from '@/services/GruposRelatoriosService';

export default {
  props: {
    relatorio: {
      type: Object,
      default: () => {}
    }
  },

  data: () => ({
    grupos: [],
    grupoId: null,
    nome: '',
    consultaSQL:
      'select row_number() over() as numero_linha from tabela where company_id = {{company_id}}',
    descricao: '',
    tab: null,
    itemsTabs: ['Consulta SQL', 'Descrição'],
    exclusivo: 'Não'
  }),

  computed: {
    codigoSQL() {
      let sql = this.consultaSQL.trim();
      return sql.substr(-1) !== ';' ? sql : sql.substr(0, sql.length - 1);
    }
  },

  created() {
    this.getGrupos();
    if (this.$route.params.id) this.carregarValoresCampos();
  },

  methods: {
    getGrupos() {
      GruposRelatoriosService.getGruposRelatorios().then((response) => {
        this.grupos = response.data.data;
      });
    },

    carregarValoresCampos() {
      Object.assign(this.$data, this.relatorio);
    },

    adicionarParametroSQL(nome) {
      this.consultaSQL += ` {{${nome}}}`;
    },

    removerParametroSQL(nome) {
      this.consultaSQL = this.consultaSQL.replace(`{{${nome}}}`, '');
    },

    exportCampos() {
      return {
        nome: this.nome,
        descricao: this.descricao,
        codigo_sql: this.codigoSQL,
        grupo_relatorio_id: this.grupoId,
        exclusivo: this.exclusivo == 'Sim' ? true : false
      };
    }
  }
};
</script>

<style>
.ta-padding-c .v-text-field__slot {
  padding: 8px;
}
.style-slot-btn-grupos-rm .v-input__append-outer {
  margin-top: 6px !important;
}
.grupo-rm-min-width fieldset {
  min-width: 130px !important;
}
</style>
