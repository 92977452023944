var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-material-card',{staticClass:"px-5 py-4 ml-0 pb-10",attrs:{"color":"primary","icon":"mdi-file-plus-outline","inline":""},scopedSlots:_vm._u([{key:"after-heading",fn:function(){return [_c('div',{staticClass:"display-2 font-weight-light"},[_vm._v("Adicionar Relatório Modelo")])]},proxy:true}])},[_c('v-divider',{staticClass:"my-4"}),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"id":"nome-rel-m","label":"Nome do Relatório Modelo","outlined":"","error-messages":errors},model:{value:(_vm.nome),callback:function ($$v) {_vm.nome=$$v},expression:"nome"}})]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"style-slot-btn-grupos-rm grupo-rm-min-width",attrs:{"id":"grupo-rel-m","items":_vm.grupos,"item-value":"id","item-text":"titulo","label":"Grupo do Relatório Modelo","outlined":"","error-messages":errors},model:{value:(_vm.grupoId),callback:function ($$v) {_vm.grupoId=$$v},expression:"grupoId"}},[_c('template',{slot:"append-outer"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"id":"btn-crud-grupos","color":"primary","fab":"","height":"40","width":"40"},on:{"click":function($event){return _vm.$router.replace({ name: 'Grupos de Relatórios' })}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v(" mdi-file-table-box-multiple-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v(" Clique aqui para acessar o menu de cadastro / edição de grupos de relatórios ")])])],1)],2)]}}])})],1)],1),_c('v-row',{staticClass:"mt-n5 mr-10 mb-n10"},[_c('v-col',{attrs:{"cols":"6 py-0"}}),_c('v-col',{staticClass:"py-0 pr-7",staticStyle:{"padding-left":"38px !important"},attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"rules":"required","id":"exclusivo-rel-m"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":['Sim', 'Não'],"label":"Exclusivo","error-messages":errors,"outlined":""},model:{value:(_vm.exclusivo),callback:function ($$v) {_vm.exclusivo=$$v},expression:"exclusivo"}})]}}])})],1)],1),_c('v-row',{staticClass:"mt-n9"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"id":"primeiro-grupo-tabs-rm"}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-tabs',{attrs:{"grow":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.itemsTabs),function(tab){return _c('v-tab',{key:tab},[_vm._v(" "+_vm._s(tab)+" ")])}),1)],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.itemsTabs),function(tab){return _c('v-tab-item',{key:tab},[(tab == 'Consulta SQL')?[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{staticClass:"mx-4 ta-padding-c",attrs:{"rows":"16","hint":"Não insira ';' (ponto e vírgula) no final da query","no-resize":"","clearable":"","background-color":"#EEEEEE","placeholder":"Escreva aqui sua consulta SQL","error-messages":errors},model:{value:(_vm.consultaSQL),callback:function ($$v) {_vm.consultaSQL=$$v},expression:"consultaSQL"}})]}}],null,true)})]:[_c('v-textarea',{staticClass:"mx-4 ta-padding-c",attrs:{"rows":"16","no-resize":"","clearable":"","background-color":"#EEEEEE","placeholder":"Escreva aqui uma descrição sucinta do que é esse relatório modelo"},model:{value:(_vm.descricao),callback:function ($$v) {_vm.descricao=$$v},expression:"descricao"}})]],2)}),1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_vm._t("footer")],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }